import moment from "moment";
import { useState } from "react";
import { subDays } from "date-fns";
import { useQuery } from "@tanstack/react-query";

import apiClient from "../header";

export function useReportQuery({
  enabled = true,
  defaultValue = {
    filter: [],
    all: 1,
    pdf: null,
    export: null,
    key: "all",
    startDate: subDays(new Date(), 7),
    endDate: new Date(),
    pageNumber: 1,
    limit: 10
  },
} = {}) {

  const [filters, setFilters] = useState(defaultValue.filter);
  const [key, setKey] = useState(defaultValue.key);
  const [pdf, setPdf] = useState(defaultValue.pdf);
  const [limit, setLimit] = useState(defaultValue.limit);
  const [exportData, setExportData] = useState(defaultValue.export);
  const [pageNumber, setPageNumber] = useState(defaultValue.pageNumber);

  const [selectionRange, setSelectionRange] = useState({
    startDate: defaultValue.startDate,
    endDate: defaultValue.endDate,
    key: "selection",
  });

  function getList(signal) {
   const query = apiClient
      .get(`report/salesAnalysis`, {
        params: {
          filter: filters,
          all: defaultValue.all,
          key: key,
          limit: limit,
          pdf: pdf == null ? true : pdf.paginate,
          page: pageNumber,
          export: exportData?.export,
          startDate: moment(selectionRange.startDate).format("D-M-Y"),
          endDate: moment(selectionRange.endDate).format("D-M-Y"),
        },
        responseType: exportData?.response_type,
        signal,
      })
      .then(result => {
        if(exportData?.export == "xlsx"){
        return new Blob([result.data])
      } else {
      return  result.data
      }
    })
    
    return query;
  }

  const query = useQuery(
    [ "report-data-list", { filters, key, pdf, exportData, selectionRange, pageNumber }, ],
    ({ signal }) => getList(signal),
    {
      retry: 0,
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: handleSuccess,
      enabled: enabled,
    }
  );

  function handleSuccess(data) {
    document.dispatchEvent( new CustomEvent("report-data-fetched", { detail: data.data }) );
  }

  return [
    query,
    {
      setFilters: setFilters,
      setKey: setKey,
      pdf: pdf,
      setPdf: setPdf,
      exportData: exportData,
      setExportData: setExportData,
      selectionRange: selectionRange,
      setSelectionRange: setSelectionRange,
      setPageNumber: setPageNumber
    },
  ];
}
