import React, { useEffect, useRef } from "react";
import _ from "lodash";


import BusinessSummary from "../../components/report/BusinessSummary";
import StackedBarChartBlock from "../../components/charts/StackedBarChartBlock";
import LineChartBlock from "../../components/charts/LineChartBlock";
import CardComponent from "../../layouts/CardLayout";
import NoDataFound from "../../components/global/NoDataFound";
import SalesbyCategory from "../../components/report/SalesbyCategory";
import SalesbyCustomers from "../../components/report/SalesbyCustomers";
import PieChartBlock from "../../components/charts/PieChartBlock";
import NoDataTable from "../../components/global/NoDataTable";
import SimpleNumber from "../../components/report/SimpleNumber";
import TotalSales from "../../components/report/TotalSales";

export default function SalesAnalysisComponent({
  refs,
  reportData,
  preferences,
  chartSettings,
  setReportData,
  fetchFilteredData,
}) {
  return (
    <div ref={refs}>
      {!_.isEmpty(
        reportData,
        preferences,
        chartSettings,
        setReportData,
        fetchFilteredData
      ) ? (
        <div>
          <div className="header-hidden ">
            <div className="mb-4 ">
              <div className="letter-spacing-1 tiny-font text-uppercase mb-2">
                Reports
              </div>
              <h2 className="mb-2 text-capitalize">Sales Analysis</h2>
            </div>
          </div>
          <div className="row my-4 ">
            <div className="col-4">
              <div className="row">
                <div className="col-12 mb-4">
                  <SimpleNumber
                    data={reportData?.data?.sales_orders?.data}
                    title="Sales Order"
                    withCurrency={preferences}
                    filter_key="sales_orders"
                    isLoading={reportData.isLoading}
                  />
                </div>
                <div className="col-12 mb-4">
                  <BusinessSummary
                    value={reportData?.data?.business_summary?.data}
                    title="Business Summary"
                    data={reportData?.data?.business_summary?.data}
                    isLoading={reportData.isLoading}
                    filter_key="business_summary"
                    withCurrency={preferences}
                  />
                </div>
                <div className="col-12">
                  <TotalSales
                    title="Total Sales"
                    data={reportData?.data?.total_sales_by_quantities?.data}
                    value={reportData?.data?.sales_orders?.data}
                    isLoading={reportData.isLoading}
                    withCurrency={preferences}
                  />
                </div>
              </div>
            </div>
            <div className="col-8">
              <div className="row h-100">
                <div className="col-lg-12 ">
                  <StackedBarChartBlock
                    data={
                      reportData?.data?.category_sales_by_channels?.data
                        ? reportData?.data?.category_sales_by_channels?.data
                        : null
                    }
                    XAxisKey={"name"}
                    YAxisKey={"value"}
                    title="Top Category sales by channel"
                    settings={chartSettings}
                    withCurrency={preferences}
                    filter_key="category_sales_by_channels"
                    isLoading={reportData.isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {!_.isEmpty(reportData?.data?.average_sales?.data) ? (
              <div className="col-lg-12 mb-4">
                <LineChartBlock
                  data={
                    reportData?.data?.average_sales?.data
                      ? reportData?.data?.average_sales?.data
                      : null
                  }
                  XAxisKey={"date"}
                  YAxisKey={"order_sales"}
                  name={`Sales in ${preferences.currency_symbol}`}
                  title="Total Average Sales"
                  settings={chartSettings}
                  withCurrency={preferences}
                  filter_key="average_sales"
                  isLoading={reportData.isLoading}
                />
              </div>
            ) : (
              <div className="col mb-4">
                <CardComponent title="Total Average Sales">
                  <NoDataFound />
                </CardComponent>
              </div>
            )}
          </div>

          <div className="pagebreak" />
          <div className="row mb-4">
            {!_.isEmpty(reportData?.data?.top_sales_by_category?.data) ? (
              <div className="col">
                <SalesbyCategory
                  data={
                    reportData?.data?.top_sales_by_category
                      ? reportData?.data?.top_sales_by_category?.data
                      : null
                  }
                  withCurrency={preferences}
                  title="total sales by category"
                  setState={setReportData}
                  fetchFilteredData={fetchFilteredData}
                  filter_key="top_sales_by_category"
                  isLoading={reportData.isLoading}
                />
              </div>
            ) : (
              <>
                <div className="col">
                  <NoDataTable />
                </div>
              </>
            )}
          </div>
          {/* <div className="row mb-4">
            {!_.isEmpty(reportData?.data?.sales_by_customers) ? (
              <div className="col">
                <SalesbyCustomers
                  data={
                    reportData?.data?.sales_by_customers?.data
                      ? reportData?.data?.sales_by_customers?.data
                      : null
                  }
                  withCurrency={preferences}
                  title="total sales by top customers"
                />
              </div>
            ) : null}
          </div> */}

          <div className="pagebreak " />
          <div className="row">
            {!_.isEmpty(reportData?.data?.sales_by_warehouse) ? (
              <div className="col-md-6 mb-4">
                <PieChartBlock
                  title="Sales by Warehouses"
                  data={
                    reportData?.data?.sales_by_warehouse?.data
                      ? reportData?.data?.sales_by_warehouse?.data
                      : null
                  }
                />
              </div>
            ) : null}
            <div className="col-md-6 mb-4">
              <PieChartBlock
                title="Total Sales"
                data={
                  reportData?.data?.total_sales?.data
                    ? reportData?.data?.total_sales?.data
                    : null
                }
                withCurrency={preferences}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="">
          <NoDataFound />
        </div>
      )}
    </div>
  );
}
