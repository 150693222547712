import React, { Component } from 'react';
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Legend, Tooltip } from 'recharts';

import _ from 'lodash';
import colors from "../../../lang/en/Theme/Colors";

import NoDataFound from '../global/NoDataFound';
import CardComponent from '../../layouts/CardLayout';
import { abbreviateNumber } from '../../../scripts/utils';

export default function LineChartBlock(props) {
  // props?.data?.map(function (value) {
  //   value.order_sales = parseFloat(value.order_sales);
  //   return value;
  // })
  
  const sum = _.sumBy(props.data, (p) => p[props.YAxisKey]);

  const CustomTooltip = ({ active, payload, label }) => {

    if (active && payload && payload.length) {
      return (
        <div className="bg-white border border-light p-2">
          <p className="label text-break small">{`${label}`}</p>
          <p className="text-secondary"> {`${props.name} : ${abbreviateNumber(payload[0].value,props.withCurrency)}`} </p>
        </div>
      );
    }
    return null;
  };
  return (
    <>
      <CardComponent filter_key={props.filter_key} title={props.title} footer={props.footer} >
        {!_.isEmpty(props.data) ? (
          <div>
            <div className="align-items-center mb-2">
              <span className="h2">{abbreviateNumber(sum, props.withCurrency)}</span>
            </div>
           
            <ResponsiveContainer width="100%" height={180} className="bar-chart-block">
              <LineChart data={props.data} >
                <CartesianGrid stroke={colors.colors.gray400} strokeDasharray="3 3" />
                <XAxis dataKey={props.XAxisKey} tick={props.settings.font} dy={10}   allowDecimals={false} />
                <YAxis tickCount={10} tickFormatter={(value) => abbreviateNumber(value, props.withCurrency)} tick={props.settings.font} dx={-10} allowDecimals={false} />
                <Legend />
                <Tooltip labelStyle={props.settings.font} content={CustomTooltip} wrapperStyle={{ width: '30%' }} />
                <Line name={props.name} type="monotone" dataKey={props.YAxisKey} stroke={colors.colors.chartColors[0]} strokeWidth={props.settings.stroke} />
              </LineChart>
            </ResponsiveContainer>

          </div>
        ) : (
          <div className="p-4">
            <div style={{height:'178px'}}>
              <NoDataFound />
            </div>
          </div>
        )
        }
      </CardComponent>
    </>
  );
}
