
import React from 'react';
import { UilDatabase } from "@iconscout/react-unicons";
import CardComponent from '../../layouts/CardLayout';
import { Table } from 'react-bootstrap';

export default function NoDataTable() {

  return (
    <CardComponent title="total sales by category">
      <Table className="table border mb-4">
        <thead>
          <tr className="fw-bold">
            <th >Category Name</th>
            <th>No. of Orders</th>
            <th>Total sales amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="12">
              <div className="text-center bg-white py-5">
                <UilDatabase size="50" className="mb-3 text-secondary" />
                <h6 className="fw-bold">No data found!</h6>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    </CardComponent>
  )

}
